import React, { useState } from "react";
import "./form.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "bootstrap/dist/css/bootstrap.min.css";

function ApplicationForm() {
  const [selectedService, setSelectedService] = useState(null);

  const handleCheckboxChange = (event) => {
    setSelectedService(event.target.value);
  };
  return (
    <div>
      <div className="empty"></div>
      <div className="application-form-container">
        <div className="upload-photo">
          <div className="upload-input">
            <input required type="file" placeholder="Upload here" />
            <span>Upload Photograph</span>
          </div>
        </div>
        <div className="app-heading">Application Form</div>
        <div className="app-form">
          <div className="choose-service">
            <label className="service-label">
              FTTH
              <input
                type="checkbox"
                value="ftth"
                checked={selectedService === "ftth"}
                onChange={handleCheckboxChange}
              />
            </label>
            <label className="service-label">
              Broadband
              <input
                type="checkbox"
                value="broadband"
                checked={selectedService === "broadband"}
                onChange={handleCheckboxChange}
              />
            </label>
            <label className="service-label">
              Broadband with New Landline
              <input
                type="checkbox"
                value="broadbandWithLandline"
                checked={selectedService === "broadbandWithLandline"}
                onChange={handleCheckboxChange}
              />
            </label>
          </div>
          <div className="main-app-form">
            <span className="require">CUSTOMER SERVICES REQUIRES:</span>
            <div className="customer-details">
              <div className="info1">
                <div className="company">
                  <span>1. Company/Organisation: </span>
                  <input required type="text" />
                </div>
                <div className="company">
                  <span>Individual: </span>
                  <input required type="text" />
                </div>
                <div className="company">
                  <span>Existing Tel. No. (If any): </span>
                  <input required type="text" />
                </div>
              </div>
              <div className="info1 info2">
                <span className="customer-deets">
                  2a. Name of the Customer /Company /Organisation (Surname /
                  First Name / Middle Name)
                  <input className="inputField" required type="text" />
                </span>
                <div className="choose-gender">
                  <label className="service-label">
                    2b. Male
                    <input
                      type="checkbox"
                      value="male"
                      checked={selectedService === "male"}
                      onChange={handleCheckboxChange}
                    />
                  </label>
                  <label className="service-label">
                    Female
                    <input
                      type="checkbox"
                      value="female"
                      checked={selectedService === "female"}
                      onChange={handleCheckboxChange}
                    />
                  </label>
                </div>
              </div>
              <div className="info3">
                3.Name of Father / Husband / Proprietor / Partner / Head of
                Organisation / Office / Branch
                <input className="inputField" required type="text" />
              </div>
              <div className="info4">
                4. Address of place where service is required{" "}
                <textarea
                  rows={3}
                  className="multiline-input"
                  required
                ></textarea>
                <div className="pin-class">
                  <input type="text" className="inputField" />
                  <span>
                    PIN CODE: <input type="text" className="pin" />
                  </span>
                </div>
              </div>
              <div className="info4">
                5. Billing Address{" "}
                <textarea
                  rows={3}
                  className="multiline-input"
                  required
                ></textarea>
                <div className="pin-class">
                  <input type="text" className="inputField" />
                  <span>
                    PIN CODE: <input type="text" className="pin" />
                  </span>
                </div>
              </div>
              <div className="info4">
                6. Permanent Address / Registered Office Address
                <textarea
                  rows={3}
                  className="multiline-input"
                  required
                ></textarea>
                <div className="pin-class">
                  <input type="text" className="inputField" />
                  <span>
                    PIN CODE: <input type="text" className="pin" />
                  </span>
                </div>
              </div>
              <div className="info3">
                7. Personal Information
                <div className="info5">
                  <div className="company per-info">
                    <span>Date of Birth: </span>
                    <input required type="date" />
                  </div>
                  <div className="company per-info">
                    <span>PAN: </span>
                    <input required type="text" />
                  </div>
                  <div className="company per-info email">
                    <span>Email: </span>
                    <input required type="email" />
                  </div>
                </div>
              </div>
              <div className="info3">
                8a. Identification Details
                <div className="info5">
                  <div className="company per-info">
                    <span>Proof of billing address: </span>
                    <select>
                      <option selected disabled>
                        Select
                      </option>
                      <option>Light Bill</option>
                      <option>Option2</option>
                    </select>
                  </div>
                  <div className="company per-info">
                    <span>Proof of identity: </span>
                    <select>
                      <option selected disabled>
                        Select
                      </option>
                      <option>Aadhar Card</option>
                      <option>Pancard</option>
                      <option>Driving Licence</option>
                      <option>Voter Id</option>
                    </select>
                  </div>
                  <div className="company per-info ">
                    <span>Proof of company: </span>
                    {/* <input required type="select" /> */}
                    <select>
                      <option selected disabled>
                        Select
                      </option>
                      <option>Payslip</option>
                      <option>Option2</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="info1 info2">
                <span className="customer-deets">
                  8b. Whether the applicant is a retired or working Employee of
                  Govt./ PSU/ CPSU
                </span>
                <div className="choose-gender">
                  <label className="service-label">
                    Yes
                    <input
                      type="radio"
                      value="yes"
                      checked={selectedService === "yes"}
                      onChange={handleCheckboxChange}
                    />
                  </label>
                  <label className="service-label">
                    No
                    <input
                      type="radio"
                      value="no"
                      checked={selectedService === "no"}
                      onChange={handleCheckboxChange}
                    />
                  </label>
                </div>
              </div>
              <div className="info3">
                9. Customer contact number
                <input className="inputField" required type="text" />
              </div>
            </div>
          </div>
          <button className="submit-app-form">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default ApplicationForm;
