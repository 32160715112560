import React from "react";
import icon from "../Assets/icon.png";
import "./login.css";
import { Link } from "react-router-dom";

function Login() {
  return (
    <div className="login-main-container">
      <img src={icon} alt="icorpLogo" />
      <svg
        className="login-svg1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 414.35 414.322"
      >
        <g
          id="Group_16"
          data-name="Group 16"
          transform="translate(-5995.863 -1507.947)"
          opacity="0.18"
        >
          <path
            id="Path_100"
            data-name="Path 100"
            d="M518.286,856.444a5.054,5.054,0,1,0,0,10.082c28.1,0,40.651-23.2,53.932-47.775,17.287-31.994,36.86-68.289,94.825-72.913a14.975,14.975,0,0,0,12.636-20.352l-25.81-68.853A180.134,180.134,0,0,0,703.634,622.7a5.109,5.109,0,0,0,1.4-4.382,5.027,5.027,0,0,0-2.688-3.71l-80.791-40.328a3.711,3.711,0,0,0-1.264-.43L519.308,553.634a5.215,5.215,0,0,0-6.076,3.844,5.055,5.055,0,0,0,3.952,5.942l98.239,19.626,25.353,67.617a70.651,70.651,0,0,1-21.508,3.737H518.286a5.055,5.055,0,1,0,0,10.082H619.268a80.354,80.354,0,0,0,25.165-4.355l25.81,68.827a5.081,5.081,0,0,1-4.033,6.748c-63.4,5.082-85.28,45.705-102.864,78.156C550.495,837.677,540.386,856.444,518.286,856.444Zm110.23-267.456,62.724,31.375a169.839,169.839,0,0,1-40.946,26.885Z"
            transform="translate(5483 974.926)"
            fill="#c3e3a8"
          />
          <path
            id="Path_101"
            data-name="Path 101"
            d="M628.932,636.15a85.845,85.845,0,1,0,85.818,85.827A85.845,85.845,0,0,0,628.932,636.15Zm0,161.554a75.736,75.736,0,1,1,75.736-75.736A75.736,75.736,0,0,1,628.932,797.7Z"
            transform="translate(5533.989 1114.429)"
            fill="#c3e3a8"
          />
          <path
            id="Path_102"
            data-name="Path 102"
            d="M616.251,643.66A65.654,65.654,0,1,0,681.878,709.3,65.654,65.654,0,0,0,616.251,643.66Zm0,121.172A55.545,55.545,0,1,1,671.769,709.3,55.545,55.545,0,0,1,616.251,764.832Z"
            transform="translate(5546.67 1127.11)"
            fill="#c3e3a8"
          />
          <path
            id="Path_103"
            data-name="Path 103"
            d="M624.731,682.938l11.077-11.077a5.057,5.057,0,1,0-7.151-7.151L617.58,675.787a25.648,25.648,0,0,0-8.872-3.683V656.456a5.055,5.055,0,1,0-10.082,0V672.1a25.652,25.652,0,0,0-8.872,3.683L578.677,664.71a5.054,5.054,0,0,0-7.125,7.151l11.077,11.077a25.192,25.192,0,0,0-3.71,8.872H563.406a5.054,5.054,0,1,0,0,10.082h15.647a25.189,25.189,0,0,0,3.71,8.872l-11.292,11.077a5.038,5.038,0,1,0,7.125,7.125l11.077-11.077a24.653,24.653,0,0,0,8.872,3.683v15.674a5.054,5.054,0,1,0,10.082,0V721.573a24.652,24.652,0,0,0,8.872-3.683l11.077,11.077a5.054,5.054,0,0,0,7.151-7.125L624.65,710.765a25.646,25.646,0,0,0,3.683-8.872h15.728a5.054,5.054,0,1,0,0-10.082H628.414A25.654,25.654,0,0,0,624.731,682.938Zm-21,29.063a15.163,15.163,0,1,1,15.136-15.163A15.164,15.164,0,0,1,603.734,712Z"
            transform="translate(5559.188 1139.559)"
            fill="#c3e3a8"
          />
          <path
            id="Path_104"
            data-name="Path 104"
            d="M581.174,608.681a5.055,5.055,0,1,0,0-10.082H540.846a5.054,5.054,0,1,0,0,10.082Z"
            transform="translate(5521.093 1050.999)"
            fill="#c3e3a8"
          />
          <path
            id="Path_105"
            data-name="Path 105"
            d="M609.616,616.192a14.463,14.463,0,0,1,7.5,2.124,5.054,5.054,0,1,0,5.162-8.684,24.573,24.573,0,0,0-12.663-3.522,25.245,25.245,0,1,0,0,50.491,24.842,24.842,0,0,0,12.663-3.522,5.054,5.054,0,1,0-5.162-8.684,14.438,14.438,0,0,1-7.5,2.1,15.163,15.163,0,1,1,0-30.3Z"
            transform="translate(5603.743 1063.705)"
            fill="#c3e3a8"
          />
          <path
            id="Path_106"
            data-name="Path 106"
            d="M518.252,899.6H558.58a25.272,25.272,0,0,0,25.245-25.245,95.947,95.947,0,1,1,191.894,0,25.245,25.245,0,1,0,50.477,0V858.706a25.273,25.273,0,0,0,20.191-24.735V793.509A25.272,25.272,0,0,0,826.2,768.774V753.181a50.166,50.166,0,0,0-2.957-15.109h38.312a45.517,45.517,0,0,0,45.436-45.463V616.872h15.136a5.054,5.054,0,0,0,5.054-5.028V571.517a5.055,5.055,0,0,0-5.055-5.055V551.326a5.054,5.054,0,1,0-10.082,0v15.136H891.824V551.326a5.054,5.054,0,1,0-10.082,0v15.136a5.054,5.054,0,0,0-5.054,5.054v40.328a5.055,5.055,0,0,0,5.054,5.028h15.136v75.736a35.381,35.381,0,0,1-35.327,35.354h-42.1c-20.03-46.35-78.344-118.86-92.4-136.013l16.938-16.965a4.974,4.974,0,0,0,1.183-5.377,5.081,5.081,0,0,0-4.3-3.334l-222.154-20.11a5.108,5.108,0,0,0-5.377,4.571,5.054,5.054,0,0,0,4.571,5.377l211.427,19.223-12.582,12.582a5.082,5.082,0,0,0-.3,6.8c22.234,26.7,72.913,91.948,92.163,133.109h-78.29a5.054,5.054,0,0,0,0,10.109h82.431a45.976,45.976,0,0,1,3.388,15.11V768.21H780.815a5.054,5.054,0,0,0-5.054,5.027v34.145a5,5,0,0,0,.86,2.689,108.561,108.561,0,0,1,17.583,44.6,5.028,5.028,0,0,0,4.974,4.275h16.965v15.136a15.163,15.163,0,1,1-30.3,0,106.029,106.029,0,1,0-212.058,0,15.19,15.19,0,0,1-15.15,15.163H518.305a5.054,5.054,0,1,0,0,10.082ZM886.9,606.79v-30.3h30.273v30.3ZM803.56,849.108a118.1,118.1,0,0,0-17.61-43.017V778.426H821.3a15.19,15.19,0,0,1,15.056,15.083v40.328A15.163,15.163,0,0,1,821.2,848.974Z"
            transform="translate(5483.034 962.043)"
            fill="#c3e3a8"
          />
        </g>
      </svg>
      <svg
        className="login-svg2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 556.111 556.111"
      >
        <g
          id="Group_15"
          data-name="Group 15"
          transform="translate(0)"
          opacity="0.37"
        >
          <path
            id="Path_76"
            data-name="Path 76"
            d="M470.728,444.193a33.908,33.908,0,1,1,67.817,0,33.114,33.114,0,0,1-11.267,25.025,6.789,6.789,0,0,0-.65,9.569,6.861,6.861,0,0,0,9.606.65,46.692,46.692,0,0,0,15.853-35.245,47.486,47.486,0,0,0-94.936,0,46.619,46.619,0,0,0,15.889,35.245,6.789,6.789,0,0,0,8.919-10.219A33.078,33.078,0,0,1,470.728,444.193Z"
            transform="translate(-321.517 -44.081)"
            fill="#118e1d"
            opacity="0.18"
          />
          <path
            id="Path_77"
            data-name="Path 77"
            d="M471.532,361.946H487.71l-22.245-44.417a6.789,6.789,0,1,1,12.133-6.067l27.12,54.167a6.789,6.789,0,0,1-6.067,9.822H482.546l22.208,44.417a6.789,6.789,0,1,1-12.133,6.067L465.5,371.768a6.789,6.789,0,0,1,6.067-9.822Z"
            transform="translate(-301.99 -280.551)"
            fill="#118e1d"
            opacity="0.18"
          />
          <path
            id="Path_78"
            data-name="Path 78"
            d="M672.175,469.849h-291.6V449.519a6.789,6.789,0,0,0-13.578,0v27.12a6.789,6.789,0,0,0,6.789,6.789H672.175a6.789,6.789,0,1,0,0-13.578Z"
            transform="translate(-122.853 72.684)"
            fill="#118e1d"
            opacity="0.18"
          />
          <path
            id="Path_79"
            data-name="Path 79"
            d="M693.757,530.57H571.666V849.323a6.789,6.789,0,0,1-6.753,6.789H456.4a6.789,6.789,0,1,1,0-13.578H557.943V523.889a6.789,6.789,0,0,1,6.789-6.789h20.33V475.645a33.945,33.945,0,0,1-27.119-33.222V333.908A33.945,33.945,0,0,1,591.852,300H673.5a33.945,33.945,0,0,1,33.909,33.908V442.422a33.944,33.944,0,0,1-27.155,33.222v41.383h20.367a6.789,6.789,0,0,1,6.789,6.789V767.964a6.789,6.789,0,0,1-13.578,0ZM666.638,476.4H598.821v40.625h67.817Zm6.861-13.65a20.367,20.367,0,0,0,20.331-20.331V333.908A20.367,20.367,0,0,0,673.5,313.578H592.1a20.367,20.367,0,0,0-20.367,20.331V442.422A20.367,20.367,0,0,0,592.1,462.753Z"
            transform="translate(-449.61 -300)"
            fill="#118e1d"
            opacity="0.18"
          />
          <path
            id="Path_80"
            data-name="Path 80"
            d="M545.313,367.61a6.789,6.789,0,0,1,6.753,6.789v54.167a6.789,6.789,0,0,1-6.753,6.789H463.919a6.789,6.789,0,0,1-6.789-6.789V374.4a6.789,6.789,0,0,1,6.789-6.789Zm-6.789,13.578H470.708v40.661h67.745Z"
            transform="translate(-321.424 -123.463)"
            fill="#118e1d"
            opacity="0.18"
          />
          <path
            id="Path_81"
            data-name="Path 81"
            d="M692.614,395.732v40.661h47.378a6.789,6.789,0,0,1,0,13.578H692.614v13.578a6.789,6.789,0,0,1-6.861,6.68H604.358a6.9,6.9,0,0,1-4.082-1.336l-27.119-20.33a6.824,6.824,0,0,1-2.708-5.453V422.779H522.892a20.366,20.366,0,0,0-20.33,20.33v54.167a20.367,20.367,0,0,0,20.331,20.331H685.753a33.944,33.944,0,0,1,33.908,33.908v54.167a33.944,33.944,0,0,1-33.908,34.269H373.789A6.789,6.789,0,0,1,367,633.162V538.118a6.789,6.789,0,1,1,13.578,0V626.3H685.753a20.367,20.367,0,0,0,20.331-20.331V551.8a20.367,20.367,0,0,0-20.331-20.331H522.892a33.944,33.944,0,0,1-33.908-33.908V443.4a33.944,33.944,0,0,1,33.908-33.908H570.45V389.159a6.825,6.825,0,0,1,2.708-5.453l27.12-20.331a6.9,6.9,0,0,1,4.081-1.336h81.394a6.789,6.789,0,0,1,6.789,6.753v13.578h47.45a6.789,6.789,0,1,1,0,13.578Zm-13.578-20.367H606.814l-22.606,16.972v47.45l22.606,16.972h72.222Z"
            transform="translate(-190.669 -138.007)"
            fill="#118e1d"
            opacity="0.18"
          />
        </g>
      </svg>
      <div className="login-container">
        <span className="login-heading">Login Account</span>
        <div className="login-box">
          <div className="login-form-container">
            <div className="login-form">
              <div className="input-class">
                <label>Username*</label>
                <input type="text" />
              </div>
              <div className="input-class">
                <label>Password*</label>
                <input type="password" />
                <span className="mandatory">*Mandatory fields</span>
              </div>
              <button className="login-btn">Sign In</button>
            </div>
            <div className="login-alternates">
              <div className="alternate">
                <span>Don't have an account?</span>
                <Link to="/register">Register now</Link>
              </div>
              <div className="alternate">
                <span>Forget your password?</span>
                <Link to="/">Reset Password</Link>
              </div>
              <div className="alternate">
                <span>Need to change your password?</span>
                <Link to="/">Change Password</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
