import React from "react";
import "./Style.css";

function LanguageDrawer() {
  const indianLang = [
    "English",
    "Hindi",
    "Marathi",
    "Kannada",
    "Gujarati",
    "Tamil",
    "Telugu",
  ];

  const handleLanguageClick = (lang) => {
    console.log(lang);
  };
  return (
    <div className="language-drawer">
      <div className="language-container">
        <div className="language-left">
          <label>India</label>
          <ul>
            {indianLang.map((language, index) => (
              <li
                className="indian-language"
                key={index}
                onClick={() => handleLanguageClick(language)}
              >
                {language}
              </li>
            ))}
          </ul>
        </div>
        <div className="language-right">
          <label>North America</label>
          <span>United States- English</span>
          <label>Asia</label>
          <span>Asia Pacific- English</span>
          <span>Chinese</span>
          <span>Japanese</span>
          <label>Europe & Middle East</label>
          <span>Deutschland- Deutsch</span>
          <span>French</span>
          <span>United Kingdom-English</span>
        </div>
      </div>
    </div>
  );
}

export default LanguageDrawer;
